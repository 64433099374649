import theme from "../../../theme"
import {Button, Grid, ThemeProvider, Typography} from "@mui/material"
import InputField from "../../../common/SACC/CareersFairContext/InputField";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown"
import Label from "../../../common/SACC/CareersFairContext/Label"
import {UUIDv4} from "../../../support";
import {useEffect, useState} from "react";
import AWS from "aws-sdk"
import {
    companyConnectList,
    courseLevelList,
    disabilitydropdown,
    interestList,
    orientationlist,
    phoneMap,
    racelist,
    religionlist,
    ssoCourseAreaList,
    titleList,
    yearList
} from "../../../assets/options";
import SACCPaper from "../../../common/SACC/SACCPaper";
import Upload from "../../../common/SACC/CareersFairContext/Upload";
import {makeStyles} from "@mui/styles";
import {useAuth} from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {SectionTitle} from "../../../common/SACC/CareersFairContext/SectionTitle";
import {signOut, sortedUniversityNames} from "../../../TSsupport";

const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: "700",
        fontSize: "32px",
    },
    logo: {
        width: "50px",
    },
    logoffButton: {
        background: "#FB8A3E !important",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px !important",
        "&:hover": {
            background: "#FB8A3E",
        },
        marginTop: "auto",
        marginBottom: "auto"
    },
    submitButton: {
        background: "#FB8A3E",
        color: "white",
        width: "50%",
        padding: "10px 0",
        fontSize: "24px",
        borderRadius: "15px"
    }
}));

const HubRegister = (props) => {

    const reverseMapping = o => Object.keys(o).reduce((r, k) =>
        Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }), {})

    const classes = useStyles();

    const [title, setTitle] = useState("");
    const [fullName, setFullName] = useState("");
    const [nickname, setNickname] = useState("");
    const [address, setAddress] = useState("");
    const [yearOfStudy, setYearOfStudy] = useState("-");
    const [courseArea, setCourseArea] = useState("");
    const [level, setLevel] = useState("");
    const [courseName, setCourseName] = useState("");
    const [lineID, setLineID] = useState("");
    const [countryCode, setCountryCode] = useState("44");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [fileLink, setFileLink] = useState("");
    const [phoneMapRev, setPhoneMapRev] = useState({});
    const [countries, setCountries] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [profileExists, setProfileExists] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [dob, setDOB] = useState("");
    const [companyConnect, setCompanyConnect] = useState("-SELECT AN OPTION-XXX");
    const [universitySelect, setUniversitySelect] = useState("");
    const [universityValue, setUniversityValue] = useState("");
    const {setProfile, getProfile} = useAuth();
    const [expectedGraduation, setExpectedGraduation] = useState("Expected"); // Default to "expected"
    const [furtherEducation, setfurtherEducation] = useState("No");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [gpa, setGpa] = useState("");
    const [skills, setSkills] = useState("");
    const [quali, setQuali] = useState("");
    
    const [orientation, setOrientation] = useState("");
    const [race, setRace] = useState("");
    const [religion, setReligion] = useState("");
    const [disability, setDisability] = useState("");
    const [hasWorkExperience, setHasWorkExperience] = useState("No");
    const [workExperience, setWorkExperience] = useState([]);
    const [transcriptFile, setTranscriptFile] = useState();
    const [transcriptFileName, setTranscriptFileName] = useState("");
    const [transcriptFileLink, setTranscriptFileLink] = useState("");
    const [interests, setInterests] = useState([]); // Array for selected interests
    const [customInterest, setCustomInterest] = useState(""); // String for custom input
    const [allInterests, setAllInterests] = useState([]); // Combined array





    
    function onChangeFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }

    function onChangeTranscriptFile(event) {
        setTranscriptFile(event.target.files[0]);
        setTranscriptFileName(event.target.files[0].name);
    }
    

    function getData() {
        props.setShowCover(true);
        // checkToken("/cfas/hub/home", userID, tokenIDh, secret);

        getProfile(true).then(async (res) => {
            setLoadingProfile(false);
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                setTitle(data.data["title"] || ""); // Add this line
                setFullName(data.data["fullName"]);
                setNickname(data.data["nickname"]);
                setAddress(data.data["address"] || ""); // Add this line
                setYearOfStudy(data.data["year"]);
                setCourseArea(data.data["courseArea"]);
                setLevel(data.data["level"]);
                setCourseName(data.data["courseName"]);
                setLineID(data.data["lineID"]);
                setCountryCode(data.data["phoneNumber"].split("//")[0] === "" ? '44' : data.data["phoneNumber"].split("//")[0]);
                setPhoneNumber(data.data["phoneNumber"].split("//")[1]);
                setGender(data.data["gender"]);
                setFileLink(data.data["cvLink"]);
                setProfileExists(true);
                setExpectedGraduation(data.data["expectedGraduation"] || "expected"); // Fetch Expected Graduation
                setfurtherEducation(data.data["furtherEducation"] || "");
                setStartDate(data.data["startDate"] || ""); // Fetch Start Date
                setEndDate(data.data["endDate"] || ""); // Fetch End Date
                setGpa(data.data["gpa"] || ""); // Fetch GPA
                setSkills(data.data["skills"]|| "");
                setQuali(data.data["quali"]|| "");
                setInterests(data.data["interests"] || []);
                setOrientation(data.data["orientation"]);
                setRace(data.data["race"]);
                setReligion(data.data["religion"]);
                setDisability(data.data["disability"]);
                setCompanyConnect(data.data["companyConnect"] ? companyConnectList[1] : (data.data["companyConnect"] === "" ? companyConnectList[0] : companyConnectList[2]));
                setWorkExperience(data.data["workExperience"] || []);
                setHasWorkExperience(data.data["hasWorkExperience"] || "No");


                if (Object.keys(data.data).includes("university") && data.data["university"].includes("//")) {
                    setUniversitySelect("Other");
                    setUniversityValue(data.data["university"].replace("Other//", ""));
                } else {
                    setUniversitySelect(data.data["university"]);
                    setUniversityValue(data.data["university"]);
                }

                setDOB(data.data["birthdate"]);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                console.log("New application");
            } else {
                alert(data.data["reason"]);
                if (data.data["reason"] === "Re-login Required") {
                    signOut();
                    localStorage.setItem("usePermsLink", "YES")
                }
            }
            props.setShowCover(false);
        }).catch((error) => {
            setLoadingProfile(false);
            console.log(error)
            props.setShowCover(false);
        })
    }

    function submitHandler() {
        const cfasUserCvBucket = "cfas-user-cv-bucket";
        const bucketRegion = "eu-west-2";
        const IdentityPoolId = "eu-west-2:67102926-3c2f-4e94-b91a-8058ee31d8b8";
    
        if (submitting) {
            return;
        }
    
        console.log(companyConnect);
    
        // Check each field individually and collect missing fields
        const missingFields = [];
        if (nickname === "") missingFields.push("Nickname");
        if (fullName === "") missingFields.push("Full Name");
        if (yearOfStudy === "-") missingFields.push("Year of Study");
        if (courseArea === "") missingFields.push("Course Area");
        if (level === "") missingFields.push("Level");
        if (courseName === "") missingFields.push("Course Name");
        if (lineID === "") missingFields.push("Line ID");
        if (phoneNumber === "") missingFields.push("Phone Number");
        if (gender === "") missingFields.push("Gender");
        if (companyConnect === "-SELECT AN OPTION-") missingFields.push("Company Connect");
        if (universityValue === "") missingFields.push("University");
        if (title === "") missingFields.push("Title");
        if (address === "") missingFields.push("Address");

        // If there are missing fields, display a detailed error message
        if (missingFields.length > 0) {
            alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
            return;
        }
    
        if (isNaN(+phoneNumber)) {
            alert("Invalid Phone Number");
            return;
        }

        setSubmitting(true);
    
        if (fileLink !== "" && transcriptFileLink !== "" && fileName === "" && transcriptFileName === "") {
            saveToDB(fileLink, transcriptFileLink);
            return;
        }
    
        AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IdentityPoolId,
            }),
        });

        let cvKey = "";
        let transcriptKey = "";
        let cvUpload = { promise: () => Promise.resolve() };
        let transcriptUpload ={ promise: () => Promise.resolve() };

        if (fileLink !== "") {
            cvKey = fileLink;
        }

        if (transcriptFileLink !== "") {
            transcriptKey = transcriptFileLink;
        }

        console.log(fileName);
        if (fileName !== "") {
            console.log("TO REPLACE FILE")
            cvKey = encodeURI(UUIDv4() + fileName.replace(/[^A-Za-z0-9.]/g, ""));
            cvUpload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: cfasUserCvBucket,
                    Key: `cv/${cvKey}`,
                    Body: file,
                },
            });
        }

        if (transcriptFileName !== "") {
            transcriptKey = encodeURI(UUIDv4() + transcriptFileName.replace(/[^A-Za-z0-9.]/g, ""));
            transcriptUpload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: cfasUserCvBucket,
                    Key: `transcripts/${transcriptKey}`,
                    Body: transcriptFile,
                },
            });
        }
    
        Promise.all([cvUpload.promise(), transcriptUpload.promise()])
            .then(() => {
                const cvLink = cvKey.includes(`https://${cfasUserCvBucket}.s3`) ? cvKey : `https://${cfasUserCvBucket}.s3.${bucketRegion}.amazonaws.com/cv/${cvKey}`;
                const transcriptLink = transcriptKey.includes(`https://${cfasUserCvBucket}.s3`) ? transcriptKey : `https://${cfasUserCvBucket}.s3.${bucketRegion}.amazonaws.com/transcripts/${transcriptKey}`;
                saveToDB(cvLink, transcriptLink);
            })
            .catch((err) => {
                alert(err.message);
                setSubmitting(false);
            });
    }
    
    function saveToDB(cvLink, transcriptLink) {
        const formData = {
            "fullName": fullName,
            "nickname": nickname,
            "year": yearOfStudy,
            "courseArea": courseArea,
            "level": level,
            "courseName": courseName,
            "lineID": lineID,
            "phoneNumber": countryCode.replace("+", "") + "//" + (phoneNumber - 0).toString(),
            "gender": gender,
            "cvLink": cvLink,
            "transcriptLink": transcriptLink,
            "companyConnect": companyConnect === companyConnectList[1],
            "university": universityValue,
            "birthdate": !isNaN(new Date(dob).getTime()) ? dob : interpretOldDate(dob).toISOString().split('T')[0],
            "title": title,
            "address": address,
            "expectedGraduation": expectedGraduation,
            "furtherEducation": furtherEducation,
            "startDate": startDate,
            "endDate": endDate,
            "gpa": gpa,
            "skills": skills,
            "quali": quali,
            "interests": allInterests,
            "orientation": orientation,
            "race": race,
            "religion": religion,
            "disability": disability,
            "workExperience": hasWorkExperience === "Yes" ? workExperience : [],
            "hasWorkExperience": hasWorkExperience,
        };
    
        console.log("FormData being sent:", formData);
    
        setProfile(formData)
            .then(async (res) => {
                const data = await res.json();
                console.log(res);
                console.log(data);
                if (res.status === 200) {
                    window.location = "/cfas/hub/home";
                } else {
                    alert(data.data["reason"]);
                    setSubmitting(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setSubmitting(false);
            });
    }
    

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            // Existing logic to fetch data
            getData();
    
            // New logic to fetch profile data and update transcript link
            getProfile(true)
                .then(async (res) => {
                    const data = await res.json();
                    if (res.status === 200) {
                        // Set the link for the transcript if it exists
                        setTranscriptFileLink(data.data["transcriptLink"] || "");
                        console.log("Transcript Link:", data.data["transcriptLink"]); // Debugging
                    } else {
                        console.log("Failed to fetch profile data", res);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching profile data:", error);
                });
    
            // Existing logic for phone map and countries
            setPhoneMapRev(reverseMapping(phoneMap));
            setCountries(Object.keys(phoneMap));
            setFirstLoad(false);
        }
    }, [firstLoad]);

    useEffect(() => {
        if (customInterest.trim()) {
            setAllInterests([...interests, customInterest]);
        } else {
            setAllInterests(interests);
        }
    }, [interests, customInterest]);
    
    
    function interpretOldDate(dateString) {
        let parts = dateString.split("-");
        return new Date(parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10) + 1)
    }
    

    return (
        <ThemeProvider theme={theme}>
            {!loadingProfile && <Grid container direction="row" justifyContent="center">
                <Grid container item direction="column" justifyContent="center" padding="100px" spacing={4} sx={{maxWidth: 1600}}>
                    <Grid container item justifyContent="space-between" rowSpacing={3}>
                        <CFASHeader classes={classes} secondaryURL={"/cfas/hub/home"} secondaryText={profileExists ? "Back to Home" : undefined}>
                            CFAS Apply
                        </CFASHeader>
                    </Grid>
                    <Grid item sx={{paddingTop: 20}}>
                        <Grid container spacing={5}>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>Title</Label>
                                </Grid>
                                <Grid item>
                                    <Dropdown items={titleList} value={title} onChange={(e) => setTitle(e.target.value)}/>
                                    {/*<InputField*/}
                                    {/*    fullWidth*/}
                                    {/*    type="text"*/}
                                    {/*    value={title}*/}
                                    {/*    onChange={(e) => setTitle(e.target.value)}*/}
                                    {/*/>*/}
                                </Grid>
                            </Grid>


                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Given Name(s) and Last Name </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} disabled/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Nickname </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Date of Birth </Label>
                                </Grid>
                                <Grid item>
                                    <InputField
                                        fullWidth
                                        type="date"
                                        value={dob && (!isNaN(new Date(dob).getTime())) ? dob : (!isNaN(interpretOldDate(dob).getTime())? interpretOldDate(dob).toISOString().split('T')[0] : new Date().toISOString().split('T')[0])}
                                        onChange={(e) => setDOB(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>Address</Label>
                                </Grid>
                                <Grid item>
                                    <InputField
                                        fullWidth
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Latest University </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={sortedUniversityNames()} value={universitySelect} onChange={(e) => {setUniversitySelect(e.target.value); (e.target.value === "Other") ? setUniversityValue("") : setUniversityValue(e.target.value)}}/>
                                    </Grid>
                                </Grid>
                                {(universitySelect === "Other") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Enter University Name </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={universityValue} onChange={(e) => setUniversityValue(e.target.value)}/>
                                    </Grid>
                                </Grid>}
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Gender </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={["Male", "Female", "Other", "Prefer not to Say"]} value={gender} onChange={(e) => setGender(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Year of Study </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={yearList} value={yearOfStudy} onChange={(e) => setYearOfStudy(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Course Area </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={ssoCourseAreaList} value={courseArea} onChange={(e) => setCourseArea(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Level </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={courseLevelList} value={level} onChange={(e) => setLevel(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Course Name </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label>Expected Graduation</Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown
                                            items={["Expected", "Achieved"]}
                                            value={expectedGraduation}
                                            onChange={(e) => setExpectedGraduation(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label>Start Date</Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            fullWidth
                                            type="date"
                                            value={startDate && !isNaN(new Date(startDate).getTime()) ? startDate : new Date().toISOString().split('T')[0]}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label>End Date</Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            fullWidth
                                            type="date"
                                            value={endDate && !isNaN(new Date(endDate).getTime()) ? endDate : new Date().toISOString().split('T')[0]}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label>GPA (Optional)</Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            fullWidth
                                            type="text"
                                            value={gpa}
                                            onChange={(e) => setGpa(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={12}>
                                    <Grid item>
                                        <Label>Are you considering pursuing further Education?</Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown
                                            items={["Yes", "No"]}
                                            value={furtherEducation}
                                            onChange={(e) => setfurtherEducation(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Line ID </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={lineID} onChange={(e) => setLineID(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={5}>
                                    <Grid item>
                                        <Label> Country Code </Label>
                                    </Grid>
                                    <Grid item >
                                        {countries[0] !== undefined && <Dropdown items={countries} value={countryCode !== "" ? phoneMapRev[countryCode][0] : countries[0]} onChange={(e) => {setCountryCode(phoneMap[e.target.value]); console.log(phoneMap[e.target.value])}}/>}
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={7}>
                                    <Grid item>
                                        <Label> Phone Number </Label>
                                    </Grid>
                                    <Grid item >
                                        <InputField fullWidth type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>CV File (Optional)</Label> {/* This will use your consistent Label component styling */}
                                </Grid>
                                <Grid item>
                                            <Typography variant="h6" className={classes.text}>We recommend submitting your CV. Once uploaded, it will be accessible to recruiters.<br/></Typography>
                                    </Grid>
                                <Grid item>
                                    <SACCPaper>
                                        <Upload handleChange={(e) => {onChangeFile(e)}} currentFileLink={fileLink}/>
                                    </SACCPaper>
                                </Grid>
                            </Grid>

                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>Transcript File (Optional)</Label>
                                </Grid>
                                <Grid item>
                                    <SACCPaper>
                                        <Upload text={"Transcript"} handleChange={(e) => onChangeTranscriptFile(e)} currentFileLink={transcriptFileLink}/>
                                    </SACCPaper>
                                </Grid>
                            </Grid>

                            <Grid item container direction="column" spacing={5}>
                                <Grid item container direction="column">
                                    <SectionTitle title={"Work Experience (Optional)"} />
                                    <Grid container item direction="column" spacing={1}>
                                        <Grid item>
                                            <Label>Do you have any work experience?</Label>
                                        </Grid>
                                        <Grid item>
                                            <Dropdown
                                                items={["Yes", "No"]}
                                                value={hasWorkExperience}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setHasWorkExperience(value);
                                                    if (value === "No") {
                                                        setWorkExperience([]);
                                                    } else if (value === "Yes" && workExperience.length === 0) {
                                                        setWorkExperience([{ employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" }]);
                                                    }
                                                }}
                                            />

                                        </Grid>
                                    </Grid>
                                    {hasWorkExperience === "Yes" && (
                                        <Grid container direction="column" spacing={3} sx={{ marginTop: 2 }}>
                                            {workExperience.map((experience, index) => (
                                                <Grid container item direction="column" spacing={2} key={index} sx={{ borderBottom: "1px solid #FB8A3E", paddingBottom: 2 }}>
                                                    <Grid item>
                                                        <Label>Employer {index + 1}</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="text"
                                                            value={experience.employer}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].employer = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label>Country</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="text"
                                                            value={experience.country}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].country = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label>Position</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="text"
                                                            value={experience.position}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].position = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label>Employment Date From</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="date"
                                                            value={experience.dateFrom && !isNaN(new Date(experience.dateFrom).getTime()) ? experience.dateFrom : new Date().toISOString().split('T')[0]}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].dateFrom = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label>Employment Date To</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="date"
                                                            value={experience.dateTo && !isNaN(new Date(experience.dateTo).getTime()) ? experience.dateTo : new Date().toISOString().split('T')[0]}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].dateTo = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label>Industry/Sector</Label>
                                                        <InputField
                                                            fullWidth
                                                            type="text"
                                                            value={experience.industry}
                                                            onChange={(e) => {
                                                                const newExperience = [...workExperience];
                                                                newExperience[index].industry = e.target.value;
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item container justifyContent="flex-end" sx={{ marginTop: 1 }}>
                                                        <Button
                                                            className={classes.logoffButton}
                                                            onClick={() => {
                                                                const newExperience = workExperience.filter((_, i) => i !== index);
                                                                setWorkExperience(newExperience);
                                                            }}
                                                        >
                                                            Delete Experience
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {workExperience.length < 3 && (
                                                <Grid container justifyContent="flex-start" sx={{ marginTop: 3 }}>
                                                    <Button
                                                        className={classes.logoffButton}
                                                        onClick={() => {
                                                            const newExperience = [
                                                                ...workExperience,
                                                                { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
                                                            ];
                                                            setWorkExperience(newExperience);
                                                        }}
                                                    >
                                                        Add Experience
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>



                            <Grid item container direction="column">
                                <Grid item>
                                    <SectionTitle title={"Optional Items"}/>
                                </Grid>
                                <Grid item container direction="column" spacing={3}>
                                    <Grid container item direction="column" spacing={1}>
                                        <Grid item>
                                            <Label> Please highlight your relevant skills </Label>
                                        </Grid>
                                        <Grid item>
                                            <InputField fullWidth type="text" value={skills} onChange={(e) => setSkills(e.target.value)}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="column" spacing={1}>
                                        <Grid item>
                                            <Label> Do you hold any relevant certifications? </Label>
                                        </Grid>
                                        <Grid item>
                                            <InputField fullWidth type="text" value={quali} onChange={(e) => setQuali(e.target.value)}/>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container item direction="row" spacing={5}>
                                        <Grid container item direction="column" spacing={1} xs={12} md={(orientation === "prefer to self-describe") ? 6 : 12}>
                                            <Grid item>
                                                <Label>What sector/industry are you interested in?</Label>
                                            </Grid>
                                            <Grid item>
                                            <Dropdown
                                                multiple
                                                items={interestList}
                                                value={interests}
                                                onChange={(e) =>
                                                    setInterests(
                                                        Array.isArray(e.target.value) ? e.target.value : e.target.value.split(",")
                                                    )
                                                }
                                            />

                                            </Grid>
                                            {interests.includes("Others") && (
                                                <InputField
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Please specify your interest"
                                                    value={customInterest}
                                                    onChange={(e) => setCustomInterest(e.target.value)}
                                                />
                                            )}

                                        </Grid>
                                    </Grid>


                                    <Grid container item direction="row" spacing={5}>
                                        <Grid container item direction="column" spacing={1} xs={12} md={(orientation === "prefer to self-describe") ? 6 : 12}>
                                            <Grid item>
                                                <Label> Sexual Orientation </Label>
                                            </Grid>
                                            <Grid item>
                                                <Dropdown 
                                                    items={orientationlist} 
                                                    value={orientation} 
                                                    onChange={(e) => setOrientation(e.target.value === "prefer to self-describe" ? "" : e.target.value)} 
                                                />

                                            </Grid>
                                        </Grid>
                                        {(universitySelect === "prefer to self-describe") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                        <Grid item>
                                        <Label> Please describe your sexual orientation </Label>
                                        </Grid>
                                        <Grid item>
                                        <InputField fullWidth type="text" value={orientation} onChange={(e) => setOrientation(e.target.value)}/>
                                        </Grid>
                                        </Grid>}
                                    </Grid>
                                    <Grid container item direction="row" spacing={5}>
                                        <Grid container item direction="column" spacing={1} xs={12} md={(race === "Other") ? 6 : 12}>
                                            <Grid item>
                                                <Label> Ethnicity </Label>
                                            </Grid>
                                        <Grid item>
                                            <Dropdown 
                                            items={racelist} 
                                            value={race} 
                                            onChange={(e) => setRace(e.target.value === "prefer to self-describe" ? "" : e.target.value)} 
                                            />
                                        </Grid>
                                        </Grid>
                                        {(race === "prefer to self-describe") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                        <Grid item>
                                        <Label> Please specify your ethnicity </Label>
                                        </Grid>
                                        <Grid item>
                                        <InputField fullWidth type="text" value={race} onChange={(e) => setRace(e.target.value)}/>
                                        </Grid>
                                        </Grid>}
                                    </Grid>
                                    <Grid container item direction="row" spacing={5}>
                                        <Grid container item direction="column" spacing={1} xs={12} md={(religion === "Other") ? 6 : 12}>
                                            <Grid item>
                                                <Label> Religion </Label>
                                            </Grid>
                                        <Grid item>
                                            <Dropdown 
                                                items={religionlist} 
                                                value={religion} 
                                                onChange={(e) => setReligion(e.target.value === "Other" ? "" : e.target.value)} 
                                            />

                                        </Grid>
                                        </Grid>
                                        {(religion === "Other") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                        <Grid item>
                                        <Label> Religion </Label>
                                        </Grid>
                                        <Grid item>
                                        <InputField fullWidth type="text" value={religion} onChange={(e) => setReligion(e.target.value)}/>
                                        </Grid>
                                        </Grid>}
                                    </Grid>
                                    <Grid container item direction="row" spacing={5}>
                                        <Grid container item direction="column" spacing={1} xs={12} md={(disability === "Yes") ? 6 : 12}>
                                            <Grid item>
                                                <Label> Do you have a disability or any required accommodations? </Label>
                                            </Grid>
                                        <Grid item>
                                            <Dropdown 
                                                items={disabilitydropdown} 
                                                value={disability} 
                                                onChange={(e) => setDisability(e.target.value === "Other" ? "" : e.target.value)} 
                                            />
                                        </Grid>
                                        </Grid>
                                        {(disability === "Yes") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                        <Grid item>
                                        <Label> Please specify your condition </Label>
                                        </Grid>
                                        <Grid item>
                                        <InputField fullWidth type="text" value={disability} onChange={(e) => setDisability(e.target.value)}/>
                                        </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" spacing={5}>
                                <Grid item container direction="column">
                                    <SectionTitle title={"Sign Up to DirectApply?"}/>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>
                                                Samaggi DirectApply is a service that helps you find the job or internships that fits you. Register to DirectApply and your profile will be available to companies who are currently recruiting and are looking for someone like you!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>
                                                Once you register to DirectApply, we may send your CFAS Profile (all information you have entered on any of the CFAS services) to relevant companies who will consider your profile and get in contact with you if your profile interests them.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid item>
                                                <Dropdown items={companyConnectList} value={companyConnect} setter={setCompanyConnect} onChange={(e) => setCompanyConnect(e.target.value)}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>By signing up to Samaggi DirectApply, I consent to allow Samaggi Samagom to send my CFAS profile information to companies who are recruiting through CFAS <a href={""} onClick={() => window.open("/cfas/apply/home", "_blank")}>(see list)</a>. I am aware I may receive communications from companies involved and that I may need to contact each company directly to stop receiving communications from them. I may withdraw from DirectApply at any time.<br/></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item justifyContent="center" marginTop={fileLink === "" ? 0 : 3}>
                                <Button className={classes.submitButton} type="submit" onClick={submitHandler}> {fileLink === "" ? (submitting ? "Submitting..." : "Submit") : (submitting ? "Saving..." : "Save")} </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </ThemeProvider>
    )
}

export default HubRegister