import {Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import QRCode from "react-qr-code";
import {SamaggiButton} from "../../../common/SamaggiButton";


const useStyles = makeStyles(() => basicStyle);


const EventHome = (props) => {
    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);
    const [ticketCode, setTicketCode] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);
    const {getTicket} = useAuth();
    const [ticketVerification, setTicketVerification] = useState("");
    const [name, setName] = useState("");
    const [hidden, setHidden] = useState(true);
    const [userID, setUserID] = useState("");

    function getData() {
        getTicket().then(async (res) => {
            const data = await res.json()
            props.setShowCover(false);
            if (res.status === 200) {
                setTicketCode(data.data["ticket-id"])
                console.log(data.data["ticket-id"])
                setTicketVerification(data.data["ticket-verification"])
                setName(data.data["name"])
                setUserID(data.data["user-id"])
                console.log(data)
                setLoaded(true);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                alert("Please enter additional profile information before continuing.");
                window.location = "/cfas/hub/register";
            } else {
                alert(data.data["reason"]);
                props.setShowCover(false);
            }
        }).catch((error) => {
            console.log(error)
            props.setShowCover(false);
        })
    }

    useEffect(() => {
        if (firstLoad) {
            getData();
            setFirstLoad(false);
            props.setShowCover(true);
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={6}>
                <CFASHeader secondaryText={"Back to Hub"} secondaryURL={"/cfas/hub/home"}>
                    Careers Fair & Seminar
                </CFASHeader>
                <Grid item height={"fit-content !important"}>
                    <SACCPaper sx={{overflow: "none"}}>
                        <Grid container direction={"row"} justifyContent={"space-between"} spacing={4}>
                            <Grid item container direction={"column"} xs={12}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Event Ticket
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                        Use the following QR Code to enter different sections of the event. You may screenshot this ticket and use it at the entry if you think you may not have internet connection at the venue.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {loaded && <SACCPaper sx={{overflow: "none"}} elevation={2}>
                                    <Grid container direction={"row"} spacing={5}>
                                        <Grid item container direction={"column"} xs={12} md={6} spacing={4}>
                                            <Grid item spacing={2}>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle} sx={{marginBottom: 0, width: "100%"}}>
                                                    Ticket For
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                                    {name}
                                                </Typography>
                                            </Grid>
                                            <Grid item spacing={2}>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle} sx={{marginBottom: 0, width: "100%"}}>
                                                    Ticket ID
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                                    {ticketCode}
                                                </Typography>
                                            </Grid>
                                            <Grid item spacing={2}>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle} sx={{marginBottom: 0, width: "100%"}}>
                                                    Ticket Verification
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                                    {ticketVerification}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"column"} justifyContent={"space-around"} alignContent={"center"} xs={12} md={6}>
                                            {loaded && <Grid item height={"fit-content"} width={"fit-content"}>
                                                <QRCode value={ticketCode}/>
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                </SACCPaper>}
                                {!loaded && <Grid item xs={12}>
                                    <SACCPaper sx={{overflow: "none"}} elevation={2}>
                                        <Grid container justifyContent="center" spacing={3}>
                                            <Grid item>
                                                <Typography sx={{color : "#878787", fontWeight : "700", fontSize : "32px"}}>Getting Ticket...</Typography>
                                            </Grid>
                                        </Grid>
                                    </SACCPaper>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
                <Grid item height={"fit-content !important"}>
                    <SACCPaper sx={{overflow: "none"}}>
                        <Grid container direction={"row"} justifyContent={"space-between"} spacing={4}>
                            <Grid item container direction={"column"} xs={12}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Profile Share Code
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                        Use the following QR Code to share quickly share your profile with a company. They will have access to your CFAS profile as well as your CV. They will also be able to create an application for you.
                                    </Typography>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%", fontWeight: "bold"}}>
                                        Please hide the profile link when scanning ticket for entry to speed up scanning.
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!hidden && <Grid item xs={12}>
                                <SACCPaper sx={{overflow: "none"}} elevation={2}>
                                    <Grid container direction={"row"} spacing={5}>
                                        <Grid item container direction={"column"} xs={12} md={6} spacing={4}>
                                            <Grid item spacing={2}>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle} sx={{marginBottom: 0, width: "100%"}}>
                                                    Profile For
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                                    {name}
                                                </Typography>
                                            </Grid>
                                            <Grid item spacing={2}>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle} sx={{marginBottom: 0, width: "100%"}}>
                                                    User ID
                                                </Typography>
                                                <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                                    {userID}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"column"} justifyContent={"space-around"} alignContent={"center"} xs={12} md={6}>
                                            <Grid item height={"fit-content"} width={"fit-content"}>
                                                <QRCode value={"https://sacc.samaggisamagom.com/cfas/company/view-qr?userID=" + userID}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SACCPaper>
                            </Grid>}
                            {hidden && <Grid item xs={12}>
                                <SACCPaper sx={{overflow: "none"}} elevation={2}>
                                    <Grid container justifyContent="center" spacing={3}>
                                        <Grid item>
                                            <Typography sx={{color : "#878787", fontWeight : "700", fontSize : "32px"}} >Profile Link Hidden</Typography>
                                        </Grid>
                                    </Grid>
                                </SACCPaper>
                            </Grid>}
                            <Grid item xs={12}>
                                <SamaggiButton fullWidth onClick={() => setHidden(!hidden)}>{hidden ? "Show" : "Hide"}</SamaggiButton>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default EventHome;