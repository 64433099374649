import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useState} from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {useAuth} from "../../../contexts/AuthContext";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {Colours, SamaggiTypography} from "../../../common/SamaggiTypography";
import {SamaggiButton} from "../../../common/SamaggiButton";


const useStyles = makeStyles(() => basicStyle);


const HubHome = (props) => {
    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);
    const [cvLink, setCvLink] = useState("");
    const [transcriptLink, setTranscriptLink] = useState("");
    const [profileData, setProfileData] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const {getProfile, checkAttending} = useAuth();
    const [userAttending, setUserAttending] = useState(false);
    const [attendingLoading, setAttendingLoading] = useState(true);
    const [workExperience, setWorkExperience] = useState([
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
    ]);
    

    function handleCVButton() {
        if (cvLink !== "") {
            console.log(cvLink);
            window.open(cvLink, "_blank");
        }
    }

    function handleTranscriptButton() {
        if (cvLink !== "") {
            console.log(transcriptLink);
            window.open(transcriptLink, "_blank");
        }
    }

    function getData() {

        // checkToken("/cfas/hub/home", userID, tokenID, secret);

        getProfile().then(async (res) => {
            const data = await res.json();
            if (res.status === 200) {
                setCvLink(data.data["cvLink"]);
                setTranscriptLink(data.data["transcriptLink"]);
                setProfileData(data.data);
                setWorkExperience(data.data["workExperience"] || []);
                console.log(data);
                props.setShowCover(false);
                setLoaded(true);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                window.location = "/cfas/hub/register";
            } else {
                alert(data.data["reason"]);
            }
        
        }).catch((error) => {
            console.log(error);
        });        

        setAttendingLoading(true);

        checkAttending().then(async (res) => {
            setAttendingLoading(false);
            const data = await res.json()
            if (res.status === 200) {
                setUserAttending(data.data["attending"]);
            } else {
                console.warn(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })

        
    }


    function goToTrack() {
        window.location = "/cfas/track/home";
    }

    function goToApply() {
        window.location = "/cfas/apply/home";
    }

    function goToEvent() {
        // window.location = "/cfas/event/register";
    }

    useEffect(() => {
        if (firstLoad) {
            getData();
            props.setShowCover(true);
            setFirstLoad(false);
        }
    })

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={6}>
                <CFASHeader secondaryText={"Edit Profile"} secondaryURL={"/cfas/hub/register"}>
                    Samaggi CFAS
                </CFASHeader>
                {profileData["title"] !== "" && (<>
                <Grid item>
                    <SACCPaper>
                        <SamaggiTypography gutterBottom variant="h6" bold xlarge>
                            Personal Information
                        </SamaggiTypography>
                        { loaded && (
                            <Grid container direction={largeScreen?"row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={3}>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Title
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["title"]}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Full Name
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["fullName"]} ({profileData["nickname"]})
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Date of Birth
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["birthdate"]}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item md={6} xs={12} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["address"]}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["email"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Line ID
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["lineID"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item md={6} xs={12} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Phone Number
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            +{profileData["phoneNumber"].replace("//", " ")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Gender
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["gender"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            )
                        }
                    

                        <SamaggiTypography gutterBottom variant="h6" bold xlarge sx={{marginTop: 10}}>
                            Education Information
                        </SamaggiTypography>
                        <Grid container item direction={largeScreen?"row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                            <Grid container item xs={12} lg={6} direction="column">
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        University
                                    </Typography>
                                </Grid>
                                { loaded && (<Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["university"].toString().replace("Other//", "Other – ")}
                                    </Typography>
                                </Grid>)}
                            </Grid>
                            <Grid container item xs={12} lg={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Area
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["courseArea"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Name
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["courseName"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Year of Study
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["year"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Level
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["level"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Expected Graduation
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["expectedGraduation"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Are you considering pursuing further Education?
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["furtherEducation"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Start Date
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["startDate"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        End Date
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["endDate"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        GPA
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["gpa"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <SamaggiTypography gutterBottom variant="h6" bold xlarge sx={{marginTop: 10}}>
                            Optional Information
                        </SamaggiTypography>
                        <Grid container item direction={"row"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Skills
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["skills"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Qualifications and Certifications
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["quali"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Sexual Orientation
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["orientation"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Ethnicity
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["race"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Religion
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["religion"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Disability
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["disability"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SamaggiTypography gutterBottom variant="h6" bold xlarge sx={{ marginTop: 10 }}>
                            Work Experience
                        </SamaggiTypography>
                        <Grid container direction={largeScreen ? "row" : "column"} spacing={4}>
                            {workExperience.length > 0 ? (
                                workExperience.map((exp, index) => (
                                    <Grid container item spacing={4} key={index}>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Employer
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.employer || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Country
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.country || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Position
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.position || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Industry/Sector
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.industry || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Employment Dates
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.dateFrom || "N/A"} - {exp.dateTo || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        No work experience provided.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>


                        <Grid container direction="column" spacing={4} sx={{marginTop: 7}}>
                            <Grid item container direction={"row"}>
                                <Grid item container direction={"column"} xs={9} alignItems={"space-evenly"}>
                                    <Grid item>
                                        <SamaggiTypography gutterBottom variant="h6" bold large>
                                            CV File (Optional)
                                        </SamaggiTypography>
                                    </Grid>
                                    
                                </Grid>
                                {cvLink !== "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth onClick={handleCVButton}>Download a Copy</SamaggiButton>
                                </Grid>}
                                {cvLink === "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/hub/register"}>Upload CV</SamaggiButton>
                                </Grid>}
                            </Grid>
                            <Grid item container direction={"row"}>
                                <Grid item container direction={"column"} xs={9} alignItems={"space-evenly"}>
                                    <Grid item>
                                        <SamaggiTypography gutterBottom variant="h6" bold large>
                                            Transcript File (Optional)
                                        </SamaggiTypography>
                                    </Grid>
                                </Grid>
                                {transcriptLink !== "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth onClick={handleTranscriptButton}>Download a Copy</SamaggiButton>
                                </Grid>}
                                {transcriptLink === "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/hub/register"}>Upload Transcript</SamaggiButton>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
                {!attendingLoading && !userAttending && <Grid item>
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        In-Person Event
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0}}>
                                        Register for the In-Person Careers Fair Event
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                <Grid item height={"fit-content"}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/event/register"}>CFAS Event</SamaggiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>}
                {!attendingLoading && userAttending && <Grid item>
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"} columnSpacing={3}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Registered for In-Person Event
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0}}>
                                        You already have registered for the in-person Careers Fair event. You can view your ticket and profile share code at CFAS Event.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                <Grid item height={"fit-content"}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>}  onClick={() => window.location = "/cfas/event/home"}>CFAS Event</SamaggiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>}
                <Grid item height={"fit-content !important"}>
                    <SACCPaper sx={{overflow: "none"}}>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <SamaggiTypography gutterBottom variant="h6" xlarge bold>
                                        Apply
                                    </SamaggiTypography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" className={classes.text}>
                                        {cvLink === "" ? "You must upload a CV to access Apply." : "Apply to open positions and internships."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3}>
                                {cvLink !== "" && <Grid item height={"fit-content"} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={goToApply}>CFAS Apply</SamaggiButton>
                                </Grid>}
                                {cvLink === "" && <Grid item height={"fit-content"} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/hub/register"}>Upload CV</SamaggiButton>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
                <Grid item >
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <SamaggiTypography gutterBottom variant="h6" xlarge bold>
                                        Track
                                    </SamaggiTypography>
                                </Grid>
                                <Grid item sx={{flexWrap: "no-wrap", overflowWrap: "no-wrap"}}>
                                    <Typography variant="h6" className={classes.text}>
                                        {cvLink !== "" ? "Track and view applications you've made." : "Make an application to access Track."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                {cvLink !== "" && <Grid item height={"fit-content"}>
                                    <SamaggiButton fullWidth endIcon={<ArrowRightAltIcon/>} onClick={goToTrack}>CFAS Track</SamaggiButton>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
                </>)}
                {profileData["title"] === "" && (<Grid item>
                    <SACCPaper>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <SamaggiTypography xlarge center bold colour={Colours.darkGray}>You need to update your profile to continue.</SamaggiTypography>
                            </Grid>
                            <Grid item>
                                <SamaggiButton center fullWidth onClick={() => {window.location.href = "/cfas/hub/register"}}>Update Now</SamaggiButton>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>)}
            </Grid>
        </ThemeProvider>
    );
};

export default HubHome;