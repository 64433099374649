import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import Cookies from 'universal-cookie';
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import Label from "../../../common/SACC/CareersFairContext/Label";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown";
import * as PropTypes from "prop-types";
import {TickBox} from "../../../common/SACC/CareersFairContext/Tickbox";
import InputField from "../../../common/SACC/CareersFairContext/InputField";
import {SectionTitle} from "../../../common/SACC/CareersFairContext/SectionTitle";
import {SamaggiButton} from "../../../common/SamaggiButton";



TickBox.propTypes = {
    style: PropTypes.any,
    onClick: PropTypes.func,
    checked: PropTypes.any
};

const bigTitle = {
    fontWeight: `bold`,
    color: "rgb(0,44,41)",
    marginBottom: `${theme.spacing(4)} ! important `,
    fontSize: "35px !important",
}

const EventRegister = () => {

    const [firstLoad, setFirstLoad] = useState(true);
    const {registerEvent, getProfile} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);

    const [interest, setInterest] = useState("");

    function submit() {

        if (submitting) {
            return
        } else {
            setSubmitting(true);
        }

        const formData = {
            "interest": interest
        }

        registerEvent(formData).then(async (res) => {
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                alert("Registration Complete Successfully");
                window.location = "/cfas/event/home";
            } else {
                setSubmitting(false);
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            setSubmitting(false);
            console.log(error)
        })
    }

    function checkProfile(userID, tokenID, secret) {
        setLoading(true)
        getProfile().then(async (res) => {
            const data = await res.json()
            if (res.status === 200) {
                if (data.message === "Success") {
                    setLoading(false);
                } else {
                    alert("You must have filled in profile information before registering for the event.")
                    window.location = "/cfas/hub/register"
                }
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (firstLoad) {
            checkProfile()
            setFirstLoad(false);
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={6}>
                <CFASHeader secondaryText={"Back"} secondaryURL={"/cfas/hub/home"}>
                    Careers Fair & Seminar
                </CFASHeader>
                <Grid item>
                    <SACCPaper sx={{paddingTop: 11}}>
                        <Grid container direction={"column"} rowSpacing={7}>
                            <Grid item container direction={"column"} rowSpacing={5}>
                                <Typography gutterBottom variant="h4" style={bigTitle}>
                                    Registration
                                </Typography>
                                <Label>Thank you for showing an interest in the 2025 Samaggi Careers Fair & Conference.
                                    Please confirm below that you intend to attend the in-person event.
                                </Label>
                            </Grid>

                            <Grid item container direction={"column"} rowSpacing={5}>
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h4" style={bigTitle}>
                                            Interest Form
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Label> Please help us give you a better experience by letting us know which topics you are interested in (optional). </Label>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={interest} onChange={(e) => setInterest(e.target.value)}></InputField>
                                </Grid>
                            </Grid>

                            {!loading && <Grid container item alignContent={"center"} justifyContent={"center"}>
                                <SamaggiButton fullWidth sx={{fontWeight : "600", fontSize : "24px", padding : "10px 0"}} onClick={submit}>{submitting ? "Registering" : "Register"}</SamaggiButton>
                            </Grid>}
                        </Grid>
                    </SACCPaper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default EventRegister;